import { User } from "firebase/auth";
import { networkClient } from "./networkClient";

export const FINISH_CHECKOUT = "/subscription";

export const finishCheckoutApi = async (payload: {
  session_id: string;
  user: User;
}) => {
  const { data } = await networkClient.put(FINISH_CHECKOUT, payload);
  return data;
};
