import { Button as NButton, IButtonProps } from "native-base";
import { ReactNode } from "react";
import { Animated } from "./Animated";

export const EmptyButton = ({
  children,
  onClick,
  ...props
}: {
  children?: ReactNode | ReactNode[];
  type?: string;
  name?: string;
  onClick?: (e: any) => void;
} & IButtonProps) => (
  <button
    style={{
      border: "none",
      outline: "none",
      boxShadow: "none",
      backgroundColor: "transparent",
      pointerEvents: "all",
      // width: "100%",
    }}
    onMouseDown={onClick}
  >
    <Animated.Button
      bg="transparent"
      w="100%"
      h="100%"
      p="0"
      m="0"
      _hover={{ bg: "text.300" }}
      _pressed={{ bg: "text.200" }}
      whileTap={{ scale: 0.95, opacity: 0.8 }}
      initial={{ opacity: 0.6 }}
      animate={{ opacity: 1 }}
      transition={{
        type: "spring",
        stiffness: 300,
        damping: 30,
        mass: 0.2,
      }}
      // zIndex={200}
      {...props}
    >
      {children}
    </Animated.Button>
  </button>
);
