import { Routes, Route } from "react-router-dom";

import { Plan, Success } from "./public";

export const RootRouter = () => {
  return (
    <Routes>
      <Route index element={<Plan />} />
      <Route path="success" element={<Success />} />
      {/* <Route path="/" element={<CommonLayout />}>
        <Route index element={<Plan />} />
      </Route> */}
    </Routes>
  );
};
