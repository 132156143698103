import { QueryClientProvider } from "@tanstack/react-query";
import { NativeBaseProvider } from "native-base";
import { theme } from "theme";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { RootRouter } from "@router";
import { queryClient } from "@api";
import { createGlobalStyle } from "styled-components";
import { registeredModals } from "@components/Modals";
import { ModalProvider } from "@contexts";

const GlobalStyle = createGlobalStyle`
  body,html {
    font-family: 'Urbanist', serif;
    margin: 0;
    background-color: white;
    height: 100%;
  }

  #root {
    height: 100%;
  }
  #root > div {
    height: 100%;
  }
`;

export default function App() {
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <NativeBaseProvider theme={theme}>
          <BrowserRouter>
            {/* <Provider store={store}> */}
            <GlobalStyle />
            <ModalProvider modals={registeredModals}>
              <RootRouter />
            </ModalProvider>

            {/* </Provider> */}
          </BrowserRouter>
        </NativeBaseProvider>
      </QueryClientProvider>
    </React.StrictMode>
  );
}
