import { Text, Image, Box, HStack } from "native-base";
import { FC, ReactNode, RefAttributes, useRef, useEffect } from "react";

export interface NoLayoutBoxProps {}

export const NoLayoutBox: FC<any> = ({ children, ...props }) => {
  const ref: any = useRef();

  useEffect(() => {
    if (ref.current) {
      ref.current.style.pointerEvents = "none";
    }
  }, [ref.current]);

  return (
    <Box position="absolute" height="100%" bottom="0" ref={ref} {...props}>
      {children}
    </Box>
  );
};
