// import { useNavigation } from "@react-navigation/native";
import { startCheckoutApi } from "@api/";
import { Button, EmptyButton, NoLayoutBox } from "@components/";
import { useFirebaseSignIn } from "@lib/firebase";
import { IconChevronDown, IconCircleCheck } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import {
  Box,
  Center,
  Divider,
  Heading,
  HStack,
  IconButton,
  Pressable,
  Text,
  VStack,
} from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { useEffect } from "react";
import { FC } from "react";
import { useLocation } from "react-router-dom";
import { Stripe } from "utils/models/Stripe";

export interface PlanProps extends InterfaceBoxProps {}

const featureItems = [
  {
    name: "Only 10 recipes based on the ingredients you have",
    free: true,
    premium: false,
  },
  {
    name: "Personalized meal plan",
    free: false,
    premium: true,
  },
  {
    name: "Automatic shopping list based on your meal plan",
    free: false,
    premium: true,
  },
  {
    name: "NO Ads!",
    free: false,
    premium: true,
  },
  {
    name: "Advanced filters",
    free: false,
    premium: true,
  },
];

export const Plan: FC<PlanProps> = ({ ...props }) => {
  const {
    isLoading,
    data,
    mutate: startCheckout,
  }: any = useMutation<Stripe, any, any, any>({
    mutationFn: () =>
      startCheckoutApi({
        priceId: "price_1N6FmCE0UbAQnGX1beyi1p2J",
      }),
    onSuccess: (data) => {
      // window.location.replace("https://google.com");
      console.log(data.url);
      window.location.replace(data.url);
      // window.open(data.url);
    },
    onError: (error) => {
      console.log("EEE", error);
    },
  });

  const { googleSignIn } = useFirebaseSignIn({
    onSuccess: (data) => {
      // window.location.replace("https://google.com");
      console.log("REEE", data);
      window.localStorage.setItem("session", JSON.stringify(data));
      startCheckout();
    },
    onError: () => window.location.replace("https://google.com"),
  });
  // window.location.replace(
  //   "https://checkout.stripe.com/c/pay/cs_test_a1lvZOaR0VPfwA0tmh9NZt2EDqBWEwiWaXFGIy2ekiyelrskenRSLwqa0y#fidkdWxOYHwnPyd1blpxYHZxWjA0SzNDZm1ANVBnRFRrQl00UkNsSGlCZ3RtTExUNm4zXUg0XFQ8a2d2YlZWbzROaklDfFNCcV9hdkBQPDVsXzNuNTMycmhdQ3dCSVZSYHFgbzBvVnBiY31XNTVJQkt3Y2pwNScpJ2N3amhWYHdzYHcnP3F3cGApJ2lkfGpwcVF8dWAnPyd2bGtiaWBabHFgaCcpJ2BrZGdpYFVpZGZgbWppYWB3dic%2FcXdwYHgl"
  // );

  return (
    <HStack justifyContent="center" h="100%">
      <VStack space="6" h="100%" pt="16">
        <VStack
          space="6"
          maxW="400px"
          h="100%"
          alignContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Box>
            <Heading>Get your personalized recipes!</Heading>
            <Text>
              Reach your nutritional goals by eating healthy, tasty and varied
              with SmartChef Premium. Tri it for free!
            </Text>
          </Box>

          <Divider />

          <Box>
            <Heading fontSize="lg" fontWeight="medium">
              Free plan vs Premium Plan
            </Heading>

            <VStack px="6" space="2">
              <HStack justifyContent="flex-end" space="2">
                <Text bold>Free</Text>
                <Text bold color="orange.300">
                  Premium
                </Text>
              </HStack>
              {featureItems.map(({ name, free, premium }, index) => (
                <HStack bg={index % 2 ? "gray.100" : "white"}>
                  <Text w="68%">{name}</Text>
                  <HStack w="30%" justifyContent="space-between" px="6">
                    <Box>
                      {free && <IconCircleCheck size={20} color="black" />}
                    </Box>
                    <Box>
                      {premium && <IconCircleCheck size={20} color="black" />}
                    </Box>
                  </HStack>
                </HStack>
              ))}
            </VStack>
          </Box>

          <Divider />

          <Box>
            <HStack justifyContent="space-between" w="100%">
              <Heading fontSize="lg" fontWeight="medium">
                Frequently asked questions
              </Heading>
              <EmptyButton>
                <IconChevronDown size={20} color="black" />
              </EmptyButton>
            </HStack>
          </Box>
        </VStack>
      </VStack>

      <NoLayoutBox justifyContent="flex-end">
        <VStack borderTopRadius="xl" space="2" alignItems="center" h="160px">
          <EmptyButton
            bg="primary.300"
            borderWidth="1"
            onClick={() => googleSignIn()}
          >
            <HStack
              px="4"
              py="2"
              justifyContent="space-between"
              alignItems="center"
              minW="340px"
            >
              <Box>
                <Text bold>1 year</Text>
              </Box>

              <VStack alignItems="flex-end">
                <Text bold fontSize="md">
                  $19.99
                </Text>
                <Text fontSize="xs">1,66$/month</Text>
              </VStack>
            </HStack>
          </EmptyButton>
          <EmptyButton maxW="100px">
            <Text fontWeight="light">Ver mas planes</Text>
          </EmptyButton>
        </VStack>
      </NoLayoutBox>
    </HStack>
  );
};
