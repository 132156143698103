import { IModalItem } from "@utils/models";
import { UploadPost } from "./UploadPost";

export enum ModalTypes {
  "UPLOAD_POST" = "UPLOAD_POST",
}

export const registeredModals: IModalItem[] = [
  // { name: ModalTypes.UPLOAD_POST, component: UploadPost },
];
