// import { useNavigation } from "@react-navigation/native";
import { finishCheckoutApi, startCheckoutApi } from "@api/";
import { Button, EmptyButton, NoLayoutBox } from "@components/";
import { IconChevronDown, IconCircleCheck } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import {
  Box,
  Center,
  Divider,
  Heading,
  HStack,
  IconButton,
  Pressable,
  Spinner,
  Text,
  VStack,
} from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { FC, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

export interface SuccessProps extends InterfaceBoxProps {}

const featureItems = [
  {
    name: "Only 10 recipes based on the ingredients you have",
    free: true,
    premium: false,
  },
  {
    name: "Personalized meal plan",
    free: false,
    premium: true,
  },
  {
    name: "Automatic shopping list based on your meal plan",
    free: false,
    premium: true,
  },
  {
    name: "NO Ads!",
    free: false,
    premium: true,
  },
  {
    name: "Advanced filters",
    free: false,
    premium: true,
  },
];

export const Success: FC<SuccessProps> = ({ ...props }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const { isLoading, mutate: subscribeUser }: any = useMutation<
    any,
    any,
    any,
    any
  >({
    mutationFn: (payload) => finishCheckoutApi(payload),
    onSuccess: (data) => {
      window.open(`smartchef://success/${data.session_id}`);
    },
  });

  useEffect(() => {
    // window.localStorage.setItem("session", JSON.stringify({ a: 1 }));
    const user = JSON.parse(window.localStorage.getItem("session") || "");
    const session_id = params.get("session_id");
    console.log({ user, session_id });
    subscribeUser({ user, session_id });
  }, []);

  return (
    <HStack justifyContent="center" h="100%">
      <VStack space="6" h="100%" pt="16">
        <VStack
          space="6"
          maxW="400px"
          h="100%"
          alignContent="center"
          alignItems="center"
          textAlign="center"
        >
          {isLoading ? <Spinner /> : <Heading>Success!</Heading>}
        </VStack>
      </VStack>
    </HStack>
  );
};
