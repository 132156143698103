import { Firebase } from "@lib/firebase";
import { CustomError } from "@utils/helpers/CustomError";
import {
  GoogleAuthProvider,
  OAuthCredential,
  User,
  UserCredential,
} from "firebase/auth";
import { useCallback, useEffect } from "react";

export interface RedirectProviderData {
  user: UserCredential;
}
export interface useFirebaseSignInOptions {
  onSuccess?: (data: UserCredential) => void;
  onError?: (error: CustomError) => void;
}

export const useFirebaseSignIn = ({
  onSuccess = () => {},
  onError = () => {},
}: useFirebaseSignInOptions) => {
  const googleRetrive = useCallback(async () => {
    try {
      const result: UserCredential | null =
        await Firebase.google.getRedirectResult();
      if (!result) return;
      // This gives you a Google Access Token. You can use it to access Google APIs.
      const credential: OAuthCredential | null =
        GoogleAuthProvider.credentialFromResult(result);

      const token = credential?.accessToken || "";
      // The signed-in user info.
      const user = result?.user || {};

      console.log("AUTH", { credential, result });
      return result;
    } catch (error) {
      if (error instanceof CustomError) {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData?.email ?? null;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log("Auth Error:", {
          errorCode,
          errorMessage,
          email,
          credential,
        });
        throw new CustomError(error.message);
      }
    }
  }, [Firebase]);
  const googleSignIn = useCallback(async (type = "redirect") => {
    try {
      // const response = await Firebase.google.signInWithPopup();
      const response = await Firebase.google.signInWithRedirect();

      if (type === "popup") {
        const response = await Firebase.google.signInWithPopup();
        onSuccess(response);
        return;
      }
      console.log("POPPP", response);
    } catch (error) {
      if (error instanceof CustomError) {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData?.email ?? null;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log("Auth Error:", {
          errorCode,
          errorMessage,
          email,
          credential,
        });
        throw new CustomError(error.message);
      }
    }
  }, []);

  useEffect(() => {
    googleRetrive()
      .then((data) => {
        console.log("EEE", data);
        data !== undefined && onSuccess(data);
      })
      .catch((error) => onError(error));
  }, [googleRetrive, onSuccess]);

  return { googleSignIn, googleRetrive };
};
