import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  signInWithPopup,
} from "firebase/auth";

class FirebaseApp {
  private instance;
  constructor() {
    // TODO: Replace the following with your app's Firebase project configuration
    const firebaseConfig = {
      apiKey: "AIzaSyDBj1TOT4bjIsGg3o-FHnqtQA0HKHSsta4",
      authDomain: "subscription-web.firebaseapp.com",
      projectId: "subscription-web",
      storageBucket: "subscription-web.appspot.com",
      messagingSenderId: "216288323371",
      appId: "1:216288323371:web:69223b2d3c00197dafc76e",
      measurementId: "G-X09LRGEDWY",
    };

    const app = initializeApp(firebaseConfig);
    this.instance = app;
  }
  getInstance() {
    return this.instance;
  }
}

export const app = new FirebaseApp().getInstance();
export const auth = getAuth(app);

export const Firebase = {
  google: {
    getAuth: () => getAuth(app),
    signInWithPopup: () => signInWithPopup(auth, new GoogleAuthProvider()),
    signInWithRedirect: () =>
      signInWithRedirect(auth, new GoogleAuthProvider()),
    getRedirectResult: () => getRedirectResult(auth),
  },
};
