export class CustomError extends Error {
  code: string;
  customData: any;

  constructor(message: string, code?: string, customData?: any) {
    super(message);
    this.name = "CustomError";
    this.code = code || "000";
    this.customData = customData || null;
  }
}
